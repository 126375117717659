<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <section class="px-4 py-6 md:px-6 md:py-14" :class="`${bgColorClass}`">
    <div class="mx-auto max-w-6xl">
      <article
        :class="`grid w-full grid-cols-1 items-center gap-6 ${gridColsClass} md:gap-20`"
      >
        <div :class="alignmentClass">
          <NuxtImg
            v-if="block?.image"
            :id="block.image?.[0]?.id"
            :src="block.image?.[0]?.url"
            :srcset="block.image?.[0]?.srcset"
            sizes="(max-width: 768px) 50vw, 100vw"
            :alt="
              block.image?.[0]?.alt
                ? block.image?.[0]?.alt
                : block.image?.[0]?.title
            "
          />
        </div>
        <div class="flex flex-col justify-center gap-3 md:gap-4">
          <component
            :is="block?.index === 0 ? 'h1' : 'h2'"
            v-if="block?.heading"
            class="text-2xl leading-snug text-dynamic-primary md:mt-[-5px] md:text-4xl"
          >
            {{ block?.heading }}
          </component>
          <PageBuilder :blocks="block.children" :globals="globals" />
        </div>
      </article>
    </div>
  </section>
</template>
<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const bgColorClass = props?.block?.backgroundColor
  ? props.block.backgroundColor
  : 'bg-white';
const isLeftAlignedImage = props.block?.isLeftAlignedImage === 'yes';
const alignmentClass = isLeftAlignedImage ? 'md:order-0' : 'md:order-1';
const gridColsClass = isLeftAlignedImage
  ? 'md:grid-cols-[54fr_46fr]'
  : 'md:grid-cols-[46fr_54fr]';
</script>
